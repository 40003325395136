var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.multimedia_meetingmode.shareMeetingWindow,
        "custom-class": "shareMeetingLinkDialog",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.multimedia_meetingmode,
            "shareMeetingWindow",
            $event
          )
        },
        close: _vm.dialogClose
      }
    },
    [
      _c("div", { staticClass: "linkBox" }, [
        _c(
          "div",
          { staticClass: "innerBox" },
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                autosize: { minRows: 2, maxRows: 6 },
                resize: "none",
                placeholder: ""
              },
              model: {
                value: _vm.textarea1,
                callback: function($$v) {
                  _vm.textarea1 = $$v
                },
                expression: "textarea1"
              }
            }),
            _c("div", { staticClass: "meetingAddress" }, [
              _vm._v(_vm._s(`${_vm.meetingAddress}`))
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "dialogFooter" }, [
        _c(
          "div",
          {
            staticClass: "copyButton commomButtonHover",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.startCopy.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("copy")) + "\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }