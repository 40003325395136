<template>
  <el-dialog
    :visible.sync="$store.state.multimedia_meetingmode.shareMeetingWindow"
    custom-class="shareMeetingLinkDialog"
    @close="dialogClose"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="linkBox">
      <div class="innerBox">
        <!-- {{`${$store.getters.userInfo.name} ${$t("inviteMeeting")}, ${$t("clicklinkJoinMeeting")}: ${meetingAddress}`}} -->
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6 }"
          resize="none"
          placeholder=""
          v-model="textarea1"
        >
        </el-input>
        <div class="meetingAddress">{{ `${meetingAddress}` }}</div>
      </div>
    </div>
    <div class="dialogFooter">
      <div class="copyButton commomButtonHover" @click.stop="startCopy">
        {{ $t("copy") }}
      </div>
    </div>
  </el-dialog>
</template>
<script>
import * as RESTApi from "@/api/rest";
export default {
  mounted() {
    this.shareMeetingLink();
  },
  data() {
    return {
      meetingAddress: "",
      textarea1: this.$t("meetingAddress") + " : ",
    };
  },
  methods: {
    // 分享会议链接
    async shareMeetingLink() {
      let params = {
        groupId:
          this.$store.state.multimedia_meetingmode.oldMeetingGroupId ||
          this.$store.state.multimedia_meetingmode.meetingGroupId,
        // meetingId: this.$store.state.multimedia_meetingmode.oldMeetingGroupId || this.$store.state.multimedia_meetingmode.meetingChannelName
      };
      let result = await RESTApi.getShareAddress(params);
      if (result.code == 200) {
        this.meetingAddress = result.data.data.meetingAddress;
        this.$store.commit(
          "setOldMeetingHref",
          result.data.data.meetingAddress
        );
      } else {
        // this.$message({
        //   type: "error",
        //   message: result.message
        // });
      }
    },
    // 点击复制按钮
    startCopy() {
      this.$copyText(`${this.textarea1} ${this.meetingAddress}`).then(
        (res) => {
          this.$message({
            customClass: "meetingMessage",
            dangerouslyUseHTMLString: true,
            message:
              '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
              '<div class="copySucceeded"></div>' +
              "<span>" +
              this.$t("copySucceeded") +
              "</span>" +
              "</div>",
            duration: 1000,
          });
          this.dialogClose();
        },
        (err) => {
          this.$message({
            type: "error",
            message: this.$t("copyFailed"),
          });
          this.dialogClose();
        }
      );
    },
    dialogClose() {
      this.textarea1 = this.$t("meetingAddress") + " : ";
      this.$store.commit("setShareMeetingWindow", false);
    },
  },
};
</script>
<style lang="stylus">
.shareMeetingLinkDialog
  width: 450px !important;
  border-radius: 5px !important;
  margin-top 0 !important;
  .el-dialog__headerbtn .el-dialog__close{
    color: #2c2c2c;
  }
  .linkBox
    width 100%;
    box-sizing border-box;
    padding 0 10px;
    .innerBox
      width 100%;
      background #F7F7F7;
      box-sizing border-box;
      padding 16px 20px;
      font-size 14px;
      color #666666;
      border-radius 5px;
      border 1px solid #E1DFDF;
      .el-textarea__inner{
        border: none;
        padding: 0;\
        background: transparent;
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
      .meetingAddress
        background: #B3B3B3;
        color: #FFFFFF;
        border-radius: 4px;
        padding: 8px;
        margin-top: 6px;
  .dialogFooter
    width 100%;
    height 34px;
    margin-top 20px;
    display flex;
    align-items center;
    justify-content center;
    .copyButton
      width 86px;
      height 100%;
      cursor pointer;
      border-radius 5px;
      display flex;
      align-items center;
      justify-content center;
      font-size 14px;
      overflow hidden;
      color #FFFFFF;
</style>
